import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';

export default function QuoraGuide() {

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"ImpressionAI - AI marketing on social media"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Typography variant="body2" component="span" style={{ textAlign: "center", fontWeight: "bold" }}>
          Welcome to ImpressionAi's Beta!<br />
        </Typography>
        Create tens of promoting interactions in seconds!⏱<br />
        👊 Search leading social media platforms for posts<br />
        👊 Hit 'Generate answer' to get an engaging response<br />
        👊 Copy + paste response using post's link!<br />
        👊 Create custom responses using the configuration panel<br />
        <br />
        💥Stay tuned for V1.0! <Typography variant="body2" component="span" style={{ textAlign: "center", fontWeight: "bold" }}>we're adding Reddit and Twitter!💥</Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} autoFocus>
        Got it!
      </Button>
    </DialogActions>
  </Dialog>
}