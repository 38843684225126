// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";


import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyC8eVSXwOz733WSU2SF1sqV3sDrK5wkat8",
  authDomain: "querilla-c4122.firebaseapp.com",
  projectId: "querilla-c4122",
  storageBucket: "querilla-c4122.appspot.com",
  messagingSenderId: "460299338855",
  appId: "1:460299338855:web:99bda699af45eb95f280c2",
  measurementId: "G-VXBJE43MHL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions();

// set up for local function emulator
if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

// auth
const auth = getAuth()
const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: 'select_account' });
const signInWithGoogle = () => auth.signInWithPopup(googleAuthProvider);

export { functions, signInWithGoogle, googleAuthProvider, auth };

