import React, { useEffect, useState } from 'react';
import { auth } from '../firebase.js';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setCurrentUser(user === null ? undefined : user);
      setPending(false);
    })

  }, []);

  if (pending) {
    return <>Loading...</>;
  }



  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
