import {
  createContext,
  useState,
  useEffect
} from 'react';


export const SessionContext = createContext({
  setPostsAndResponses: () => { },
  postsAndResponses: { promptParams: {}, rows: [] },
});


const initialPostsAndResponses = {
  rows: [], promptParams: {}
}

export default function SessionContextProvider({ children }) {

  const [postsAndResponses, setPostsAndResponses] = useState(
    initialPostsAndResponses
  );

  useEffect(() => {
    const postsAndResponses = JSON.parse(sessionStorage.getItem('postsAndResponses'));
    setPostsAndResponses(postsAndResponses ? postsAndResponses : initialPostsAndResponses)
  }, []);


  return (
    <SessionContext.Provider value={{ postsAndResponses, setPostsAndResponses }}>
      {children}
    </SessionContext.Provider>
  );
}