import React from 'react';
import { AuthProvider } from './Auth';
import ThisIsTheOnlyPage from './ThisIsTheOnlyPage';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

export const RoutesManager = () => (
  <AuthProvider >
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
        >

          <Route exact path='/' element={<ThisIsTheOnlyPage />} />
        </Route>
        <Route
          path="/"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);

export default RoutesManager;
