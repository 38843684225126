import React, { useContext, useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Posts from './Posts';
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import { AuthContext } from './Auth';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { auth } from '../firebase';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © ImpressionAI'}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function ContactUs() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      <Link href="mailto:suissa.daniel@gmail.com">
        Contact us via email
      </Link>

    </Typography>
  );
}


export default function ThisIsTheOnlyPage() {

  const { currentUser } = useContext(AuthContext);

  const handleSignout = () => {
    auth.signOut()
  }

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth()
    signInWithRedirect(auth, provider);
  }

  return <Container maxWidth="xlg">
    <Box display="flex">
      {/* <Typography variant="h4" component="h1">
        ImpressionAI
      </Typography> */}
      {/* <img src="impressionai_logo_sm.png" width="50%" height="auto" align="center"></img>  */}
      {/* <Box display={currentUser ? 'block' : 'none'}> */}
      <Box display="flex" alignItems="left">
        <IconButton onClick={signInWithGoogle} color={currentUser ? 'disabled' : 'primary'} aria-label="sign in" component="label" style={{ pointerEvents: currentUser ? 'none' : 'auto', fontSize: 14 }}>
          <span>Login</span>
          <LogoutIcon />
        </IconButton>
        <IconButton onClick={handleSignout} color={currentUser ? 'primary' : 'disabled'} aria-label="sign out" component="label" style={{ pointerEvents: currentUser ? 'auto' : 'none', fontSize: 14 }}>
          <span>Logout</span>
          <LogoutIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfyR9ElpZQ_jG0cGYszvihcdjLvBnLvAQxGd-Z4Ke5DLzHLhw/viewform?usp=sf_link" style={{ textDecoration: "none" }}>
          <Button style={{ width: "200px", marginLeft: "10px", color: "white", backgroundColor: "#6230E9" }}>Add feedback</Button>
        </a>
        <a href="https://docs.google.com/forms/d/1VPI9Nt7wST--w4A8ViTINVNIYPpPpksN2p6BSNA81cc/edit" style={{ textDecoration: "none" }}>
          <Button style={{ width: "200px", marginLeft: "10px", color: "white", backgroundColor: "red" }}>Report bug</Button>
        </a>
      </Box>
    </Box >
    <img src="impressionai_logo_sm.png" width="25%" height="auto" style={{ display: "block", margin: "0 auto" }}></img>
    <Typography variant="subtitle1" component="h6" gutterBottom style={{ textAlign: "center" }}>
      AI powered marketing (beta version)
    </Typography>
    <Box sx={{ my: 4 }}>
      <Container maxWidth="sm">
      </Container>

      <Posts />
      <ContactUs />
      <Copyright />
    </Box>
  </Container >
}