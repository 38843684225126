import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SettingsIcon from '@mui/icons-material/Settings';
import Chip from '@mui/material/Chip';
import SendIcon from '@mui/icons-material/Send';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase.js';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import QuoraGuide from './QuoraGuide';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './Auth';
import { SessionContext } from './SessionContextProvider';
import { QuoraIcon } from './icons/QuoraIcon'
import { Typography } from '@mui/material';
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Paddle = window.Paddle;
const getPosts = httpsCallable(functions, 'getPosts');
const getResponses = httpsCallable(functions, 'getResponses');
const getCredits = httpsCallable(functions, 'getCredits');

const initialErrorState = {
  hasError: false, message: ""
}

const initialIsFetchingResponses = {}
const initialGetResponsesErrorState = {}

const interactionType = {
  DONT_PROMOTE: 1,
  PROMOTE_NO_LINK: 2,
  PROMOTE_WITH_LINK: 3
}

const postType = {
  QUORA: 1,
  LINKEDIN: 2,
  TWITTER: 3,
  REDDIT: 4
}

const cellWithEllipsisSX = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
}

const htmlTagRegex = /<[^>]*>/ig

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: '500px',
  },

}));

export default function Posts() {
  const [isFetchingPosts, setIsFetchingPosts] = useState(false);
  const [isFetchingResponses, setIsFetchingResponses] = useState(initialIsFetchingResponses);

  const [getPostsErrorState, setGetPostsErrorState] = useState(initialErrorState);
  const [getResponsesErrorState, setGetResponsesErrorState] = useState(initialGetResponsesErrorState
  )
  const [productSettingsErrorState, setProductSettingsErrorState] = useState(initialErrorState);

  const [isProductSpecDialogOpen, setIsProductSpecDialogOpen] = useState(false);

  const [selectedPostType, setSelectedPostType] = useState(postType.LINKEDIN)
  const [selectedInteractionType, setSelectedInteractionType] = useState(interactionType.DONT_PROMOTE)

  var postChoices = []

  const [creditsStatus, setCreditsStatus] = useState('')

  const { currentUser } = useContext(AuthContext);
  const { postsAndResponses, setPostsAndResponses } = useContext(
    SessionContext
  );

  useEffect(() => {
    getCredits({}).then(credits => {
      setCreditsStatus(`${credits.data}`)
    }).catch(error => {
      setCreditsStatus(`[unknown]`)
    })
  }, [])

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth()
    signInWithRedirect(auth, provider);
  }

  const setPostsAndResponsesWrapper = (newpPostAndRespones) => {
    setGetPostsErrorState(initialErrorState)
    setProductSettingsErrorState(initialErrorState);
    setPostsAndResponses(newpPostAndRespones)
  }

  const setResponseForPost = (rowLink, response) => {
    setPostsAndResponses(currentPostAndRespones => {
      return {
        rows: currentPostAndRespones.rows.map(row => {
          if (rowLink == row.link) {
            return {
              ...row,
              answer: response // if the row has an answer, add it in
            }
          }
          return row; // otherwise keep the row as is
        }),
        promptParams: currentPostAndRespones.promptParams
      }
    }
    )
  }
  const routeChange = () => {
    sessionStorage.setItem('postsAndResponses', JSON.stringify(postsAndResponses));
    signInWithGoogle()
  }

  const getHandleGetResponseClick = (rowLink) => {
    return (_) => {
      onGetResponsesClick(rowLink)
    };
  }

  const handleProductNameChange = (e) => {
    const res = {
      rows: postsAndResponses.rows,
      promptParams: {
        ...postsAndResponses.promptParams,
        productName: e.target.value
      }
    }
    setPostsAndResponsesWrapper(res)
  }

  const handleProductDescChange = (e) => {
    const res = {
      rows: postsAndResponses.rows,
      promptParams: {
        ...postsAndResponses.promptParams,
        productOneline: e.target.value
      }
    }
    setPostsAndResponsesWrapper(res)
  }

  const handleQueryChange = (e) => {
    const res = {
      rows: postsAndResponses.rows,
      promptParams: {
        ...postsAndResponses.promptParams,
        keywords: e.target.value
      }
    }
    setPostsAndResponsesWrapper(res)
  }

  const handleLinkChange = (e) => {
    const res = {
      rows: postsAndResponses.rows,
      promptParams: {
        ...postsAndResponses.promptParams,
        productLink: e.target.value
      }
    }
    setPostsAndResponsesWrapper(res)
  }


  const updateSingleIsFetchingGetResponseState = (rowLink, value) => {
    setIsFetchingResponses(currentIsFetchingResponses => {
      const newIsFetchingResponses = { ...currentIsFetchingResponses }
      newIsFetchingResponses[rowLink] = value
      return newIsFetchingResponses
    })

  }

  const updateSingleGetResponsesErrorState = (rowLink, value) => {
    setGetResponsesErrorState(currentGetResponsesErrorState => {
      const newGetResponsesErrorState = { ...currentGetResponsesErrorState }
      newGetResponsesErrorState[rowLink] = value
      return newGetResponsesErrorState
    })
  }

  const resetIsFetchingGetResponseState = (rows) => {
    const newIsFetchingResponses = {}
    rows.forEach(row => {
      newIsFetchingResponses[row.link] = false
    })
  }

  const sanitizePost = (postText) => {
    return postText.replaceAll(htmlTagRegex, '')
  }
  const validateGetResponsesParams = (params) => {
    const queryLimit = 150;
    const productNameLimit = 20;
    const linkLimit = 50;
    const descriptionLimit = 200;

    if (!postsAndResponses.promptParams.keywords || !postsAndResponses.promptParams.productName || !postsAndResponses.promptParams.productLink || !postsAndResponses.promptParams.productOneline) {
      throw new Error('One or more fields was not provided');
    }

    if (postsAndResponses.promptParams.keywords.length > queryLimit) {
      throw new Error('Your search query must be under ' + `${queryLimit} characters`);
    }

    if (postsAndResponses.promptParams.productName.length > productNameLimit) {
      throw new Error('Your product name must be under ' + `${productNameLimit} characters`);
    }

    if (postsAndResponses.promptParams.productLink.length > linkLimit) {
      throw new Error('Your promoted link must be under ' + `${linkLimit} characters`);
    }

    if (postsAndResponses.promptParams.productLink > descriptionLimit) {
      throw new Error('Your product description must be under ' + `${descriptionLimit} characters`);
    }
  }

  const handleOnChangePostType = (event) => {
    setSelectedPostType(parseInt(event.target.value))
  }

  const handleOnChangeInteractionType = (_, newSelectedInteractionType) => {
    setSelectedInteractionType(newSelectedInteractionType)
  }

  const onGetPostsClick = () => {
    setIsFetchingPosts(true)
    getPosts({ postType_: selectedPostType, keyWords: postsAndResponses.promptParams.keywords })
      .then(result => {

        // deduplicate rows 
        // This messes up Quora as it has no other fileds
        // const rows = result.data.filter((v, i, a) => a.findIndex(v2 => (v2.numReactions === v.numReactions && v2.authorName === v.authorName)) === i)
        const rows = result.data

        if (rows.length == 0) {
          setGetPostsErrorState({
            hasError: true,
            message: "It seems like your search didn't yield any results. Try again in a few seconds or with a different query"
          })
        } else {
          setPostsAndResponsesWrapper({
            rows: rows,
            promptParams: postsAndResponses.promptParams
          });
          resetIsFetchingGetResponseState(rows)

        }
        setIsFetchingPosts(false)
      })
      .catch((e) => {
        console.error(e.message)
        setIsFetchingPosts(false)
        setGetPostsErrorState({
          hasError: true,
          message: "Could not retrieve posts :/ Please try again later"
        })
      })
  }

  const onGetResponsesClick = (rowLink) => {
    if (!currentUser) { // check for auth
      routeChange()
      return
    }



    try {
      validateGetResponsesParams();
    } catch (error) {
      setProductSettingsErrorState({ message: error.message, hasError: true });
      setIsProductSpecDialogOpen(true)
      return
    }

    // get only the requested rows
    let requestedRows = []
    for (const row of postsAndResponses.rows) {
      if (row.link == rowLink) {
        requestedRows.push(row)
      }
    }


    updateSingleIsFetchingGetResponseState(rowLink, true)
    const params = {
      postType_: selectedPostType,
      interactionType_: selectedInteractionType,
      productName: postsAndResponses.promptParams.productName,
      productOneline: postsAndResponses.promptParams.productOneline,
      productLink: postsAndResponses.promptParams.productLink,
      postsJsonArray: requestedRows.map(row => {
        return {
          "postText": row.postText,
          "link": row.link,
        }
      })
    }

    getResponses(params).then(result => {
      // since we only send a single post to the function, we expect a single result
      setResponseForPost(result.data[0].link, result.data[0].answer)

      updateSingleIsFetchingGetResponseState(rowLink, false)
      updateSingleGetResponsesErrorState(rowLink, null)

      // update the credits status
      getCredits({}).then(credits => {
        setCreditsStatus(`${credits.data}`)
      }).catch(error => {
        setCreditsStatus(`[unknown]`)
      })
    }).catch(err => {
      updateSingleIsFetchingGetResponseState(rowLink, false)
      if (err.message.includes("Insufficient Credits")) {
        Paddle.Checkout.open({
          product: 44272,
          email: currentUser.email,
          passthrough: JSON.stringify({ "userUID": currentUser.uid }),
        });
      } else {
        console.error(err.message)
        updateSingleGetResponsesErrorState(rowLink, "There was a problem fetching your response. Please try again later :/ ");
      }
    })
  }

  const handleProductSpecDialogClose = () => {
    setIsProductSpecDialogOpen(false)
  }

  const onOpenSettings = () => {
    setIsProductSpecDialogOpen(true)
  }


  return (
    <Container maxWidth="xlg">

      <Container >
        <Dialog
          open={isProductSpecDialogOpen}
          onClose={handleProductSpecDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            Product \ Brand Details
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              {productSettingsErrorState.hasError && <Alert severity="error">{productSettingsErrorState.message}</Alert>}
              <Box>
                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Select engagment type:
                </div>
                <ToggleButtonGroup
                  value={selectedInteractionType}
                  exclusive
                  onChange={handleOnChangeInteractionType}
                  aria-label="text alignment"
                  style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                  <ToggleButton value={interactionType.DONT_PROMOTE} aria-label="interact" style={{ marginLeft: '55px', marginUp: '20px', marginBottom: '10px' }}>
                    <Typography variant='button'>INTERACT</Typography>
                  </ToggleButton>
                  <ToggleButton value={interactionType.PROMOTE_NO_LINK} aria-label="promote-product" style={{ marginUp: '20px', marginBottom: '10px' }}>
                    PROMOTE PRODUCT
                  </ToggleButton>
                  <ToggleButton value={interactionType.PROMOTE_WITH_LINK} aria-label="promote-with-link" style={{ marginUp: '20px', marginBottom: '10px' }}>
                    PROMOTE WITH LINK
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <div style={{ fontSize: '14px' }}>
                Product \ brand name (if promoting)
              </div>
              <TextField id="outlined-basic" label="ex: Turbo Charger" variant="outlined" value={postsAndResponses.promptParams.productName} onChange={handleProductNameChange} />
              <div style={{ fontSize: '14px' }}>
                Product \ brand description (if promoting)
              </div>
              <TextField id="outlined-basic" label="ex: fast charger for electric vehicles" variant="outlined" value={postsAndResponses.promptParams.productOneline} onChange={handleProductDescChange} />
              <div style={{ fontSize: '14px' }}>
                Product \ brand link (if promoting with link)
              </div>
              <TextField id="outlined-basic" label="ex: www.turbocharger.ai" variant="outlined" value={postsAndResponses.promptParams.productLink} onChange={handleLinkChange} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleProductSpecDialogClose} autoFocus>
              Done
            </Button>
          </DialogActions>
        </Dialog>

      </Container>

      {
        (postsAndResponses.rows.length == 0) && <Container maxWidth="sm">
          <QuoraGuide />
        </Container>
      }

      <Container
        component="form"
        sx={{
          '& > :not(style)': { m: 1, },
        }}
        maxWidth="sm"
        noValidate
        autoComplete="off"
      >
        <Stack spacing={2}>
          <TextField id="outlined-basic" label="Exact terms seperated by comma. Example: cloud native, kubernetes" variant="outlined" onChange={handleQueryChange} />
          <Box display="flex">
            <LoadingButton sx={{ marginRight: '15%', minWidth: '40%' }} loading={isFetchingPosts} endIcon={<SendIcon />} loadingPosition="end" variant="contained" onClick={onGetPostsClick}>Search Posts</LoadingButton>
            <Box sx={{ marginRight: '15%' }}>from</Box>
            <Box>
              <FormControl fullWidth>
                {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Social Media
                </InputLabel> */}
                <NativeSelect
                  defaultValue={postType.LINKEDIN}
                  onChange={handleOnChangePostType}
                >
                  <option value={postType.LINKEDIN}>LinkedIn</option>
                  <option value={postType.QUORA}>Quora</option>
                  <option disabled={true} value={postType.TWITTER}>Twitter</option>
                  <option disabled={true} value={postType.REDDIT}>Reddit</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Box>
        </Stack>

        {getPostsErrorState.hasError && <Alert severity="error">{getPostsErrorState.message}</Alert>}
      </Container>

      {
        (postsAndResponses.rows.length > 0) &&
        <Box display='flex'>
          {currentUser && <Chip label={`${creditsStatus} credits`} variant="outlined" />}
          <IconButton onClick={onOpenSettings} color="primary" aria-label="open product desc dialog" >
            <SettingsIcon />
            <Typography>
              Configure Promotion Details
            </Typography>
          </IconButton>
        </Box>
      }

      {
        (postsAndResponses.rows.length > 0) &&
        <TableContainer component={Paper} >
          <Table aria-label="simple table">
            <colgroup>
              <col style={{ width: '6%' }} />
              <col style={{ width: '12%' }} />
              <col style={{ width: '18%' }} />
              <col style={{ width: '32%' }} />
              <col style={{ width: '32%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="left">Author Name</TableCell>
                <TableCell align="left">Author Position</TableCell>
                <TableCell align="left">Reactions</TableCell>
                <TableCell align="left">Post</TableCell>
                <TableCell align="left">Generated Response</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {postsAndResponses.rows.map((row, index) => (
                <TableRow
                  key={`${row.link}-${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">
                    <Typography sx={cellWithEllipsisSX}>{row.authorName}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <BootstrapTooltip
                      title={<Typography >{row.authorPosition}</Typography>}>
                      <Typography sx={cellWithEllipsisSX} >{row.authorPosition}</Typography>
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={cellWithEllipsisSX} >{row.numReactions}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Box display='flex'>
                      <BootstrapTooltip
                        title={<Typography >{sanitizePost(row.postText)}</Typography>}>
                        <Typography sx={cellWithEllipsisSX}>{sanitizePost(row.postText)}</Typography>
                      </BootstrapTooltip>
                      <IconButton href={row.link} color="primary" aria-label="new tab" target="_blank">
                        <OpenInNewIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell align="left">

                    {!row.answer && <LoadingButton sx={{ minWidth: '80%' }} loading={isFetchingResponses[row.link]} endIcon={<SendIcon />} loadingPosition="end" variant="contained" onClick={getHandleGetResponseClick(row.link)}>Get Response</LoadingButton>}
                    {getResponsesErrorState[row.link] && getResponsesErrorState[row.link].length > '0' && <Alert severity="error">{getResponsesErrorState[row.link]}</Alert>}

                    <BootstrapTooltip
                      title={<Typography>{row.answer}</Typography>}>
                      <Typography sx={cellWithEllipsisSX}>{row.answer}</Typography>
                    </BootstrapTooltip>
                    {row.answer && row.answer.length > 0 && <IconButton onClick={() => { navigator.clipboard.writeText(row.answer) }} color="primary" aria-label="copy-to-clipboard">
                      <ContentCopyIcon />
                    </IconButton>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Container >

  );
};
